<template>
  <div class="background pb-5">
    <!-- Muestra mensaje de previsualización si está en modo 'preview' -->
    <div v-if="$route.params.mode == 'preview'" class="bg-danger text-white py-1 ajuste z-index-2 px-4">
      Usted se encuentra en un entorno de pre-visualización
    </div>

    <!-- Muestra mensaje de error si hay algún problema -->
    <div v-if="error" class="no-result center-element">
      <span>Lo sentimos se produjo un error</span>
    </div>

    <div class="row" style="margin-left: 1px">
      <div class="col-lg-8" style="margin-top: 30px">
        <div class="pb-1">
          <!-- Título del curso -->
          <strong>{{ this.courseInfo.title }}</strong>
        </div>

        <!-- Componente de video, se muestra si renderVideo es true -->
        <Video v-if="renderVideo" :classId="lessonId" :courseId="this.$route.query.course"
          @markLessonComplete="handleLessonComplete"></Video>

        <!-- Componente de descripción, solo si lessonId está definido -->
        <div class="mt-3">
          <Descripcion :id_lesson="lessonId" v-if="lessonId"></Descripcion>
        </div>
      </div>

      <div class="col-lg-4" style="background-color: #f2f5fa">
        <!-- Componente de docente -->
        <Docente></Docente>

        <!-- Componente de temario, con eventos de lección completada -->
        <Temario :completedLessons="completedLessons" @markLessonAsCompleted="handleLessonComplete" />

        <!-- Botón para invitar a otra persona -->
        <div class="text-center mb-3">
          <v-btn depressed color="#1ae800" class="text-white">Invitar a otra persona</v-btn>
        </div>

        <!-- Componente de comentarios -->
        <Comentarios></Comentarios>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState, mapActions, mapMutations } from "vuex";
import Temario from "@/components/course/temario";
import Descripcion from "@/components/course/descripcion";
import Comentarios from "@/components/course/comentarios";
import Video from "@/components/course/video";
import Docente from "@/components/course/docente";

export default {
  name: "Course",
  data() {
    return {
      error: false,
      lessonId: "",
      courseInfo: [],
      completedLessons: [],
    };
  },
  components: {
    Temario,
    Descripcion,
    Comentarios,
    Video,
    Docente,
  },
  computed: {
    ...mapState("course", ["lesson", "renderVideo", "courseSelect"]),
  },
  methods: {
    ...mapActions("course", {
      courseSelectedStatus: "courseSelectedStatus",
      getLesson: "getLesson",
      getVideo: "getVideo",
      lastSeenLesson: "lastSeenLesson",
      getComments: "getComments",
      getRating: "getRating",
      getCourseActive: "getCourseActive",
      getTest: "getTest",
      getModuleExam: "getModuleExam",
      getCourseRating: "getCourseRating",
      getActiveDinamicModule: "getActiveDinamicModule",
    }),

    ...mapMutations("course", [
      "GET_PROGRESS",
      "DESTROY_PROGRESS",
      "CLEAR_ALL_DATA",
    ]),

    handleLessonComplete(lessonId) {
      console.log(
        "Emitir evento de lección completada con lessonId: ",
        lessonId
      );
      this.$emit("markLessonAsCompleted", lessonId);
      this.completedLessons.push(lessonId);
    },

    async getCourseInfo() {
      await this.axios
        .get("course/details/" + this.$route.query.course)
        .then((response) => {
          console.log(response);
          this.courseInfo = response.data.data;
        });
    },

    // Carga la lección activa en la inicialización del componente
    async activeLesson() {
      const courseId = this.$route.query.course;
      await this.axios
        .get(`class/show-class/${courseId}?name=${this.$route.query.class}`)
        .then((res) => {
          let lessonId = res.data.id;
          this.lessonId = lessonId;
          this.getLesson(res.data);
          this.getVideo(res.data.id);
          this.getComments(res.data.id);
          this.getRating(this.$route.query.course);
          this.getTest({ exam_type: "class", id_type: res.data.id });
          this.getModuleExam(this.$route.query.course);
          this.getActiveDinamicModule(this.$route.query.course);
        });
    },
  },
  mounted() {
    this.courseSelectedStatus(true);
    this.getCourseInfo();
  },
  created() {
    this.activeLesson();
    this.getCourseActive(this.$route.query.course);
    this.getCourseRating(this.$route.query.rate);
    this.$root.$refs.Course = this;
  },
  beforeMount() {
    // Verifica que los parámetros de curso y clase estén en la URL
    if (!this.$route.query.class && !this.$route.query.course) {
      this.error = true;
    } else if (!this.$route.query.class || !this.$route.query.course) {
      this.error = true;
    }
  },
  destroyed() {
    this.DESTROY_PROGRESS();

    // Enviar información de última lección visualizada
    let sendData = {
      course_id: this.$route.query.course,
      class_id: this.lesson.id,
    };

    this.lastSeenLesson(sendData);
  },
};
</script>

<style scoped>
.background {
  background-color: #f2f5fa !important;
}
</style>
